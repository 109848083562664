<template>
	<div>
		<header>
			<div class="ls-card">
				<el-page-header @back="$router.go(-1)" content="待缴费" />
			</div>
		</header>
		<div class="article-manager video-icon form-submit" id="shopp-manager">
			<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column prop="id" label="序号">
				</el-table-column>
				<el-table-column prop="group_id" label="团号">
				</el-table-column>
				<el-table-column prop="toruName" label="行程名称">
				</el-table-column>
				<el-table-column prop="date" label="入境日期">
				</el-table-column>
				<el-table-column prop="amount" label="缴费金额">
				</el-table-column>
			</el-table>
			<div style="margin-top: 20px">
				<span style="font-size: 16px;">总数量：<span style="font-size: 18px;font-weight: bold;">{{num}}</span></span>
				<span style="font-size: 16px;margin-left: 20px;">总金额：￥<span style="font-size: 18px;font-weight: bold;">{{total}}</span></span>
				<el-button style="margin-left: 20px;" @click="dialogFormVisible = true">确认缴费</el-button>
			</div>
		</div>
		<el-dialog
		  title="缴费确认"
		  :visible.sync="dialogFormVisible"
		  width="30%"
		  :before-close="handleClose">
		   <span slot="footer" class="dialog-footer">
			  <el-button @click="dialogFormVisible = false">取 消</el-button>
			  <el-button type="primary" @click="confirmPayment()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		apiGetPaymentList,
		apiConfirmPayment
	} from '@/api/visa/order';
	import config from '@/config';

	export default {
		name: 'visa',
		components: {},
		props: {},
		data() {
			return {
				loading:false,
				dialogFormVisible: false,
				total:0,
				num:0,
				tableData: [],
				multipleSelection: []
			}
		},
		computed: {

		},
		watch: {},

		mounted() {
			this.getPaymentList();
		},
		methods: {
			
			handleClose(){
				this.dialogFormVisible = false
			},
			
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.num = 0;
				this.total = 0;
				val.forEach(item=>{
					this.num ++;
					this.total += item.amount;
				})
			},
			getPaymentList() {
				this.loading = true;
				apiGetPaymentList().then(async (res) => {
					this.tableData = res
					this.num = 0;
					this.total = 0;
					res.forEach(item=>{
						this.num ++;
						this.total += item.amount;
					})
					this.loading = false;
				})
			},
			confirmPayment(){
				let that = this;
				let group_ids = [];
				that.multipleSelection.forEach(item=>{
					group_ids.push(item.group_id)
				})
				that.dialogFormVisible = false
				that.loading = true;
				apiConfirmPayment({group_ids:group_ids}).then(async (res) => {
					console.log(res,'res')
					that.loading = false;
					that.$message.success('成功')
					setTimeout(function() {
						return that.$router.push({
							path: '/visa'
						});
					}, 3000);
				})
			}
		},
	}
</script>

<style lang="scss" scoped>
	.el-card {
		max-height: 1100px !important;
	}

	.mark-item {
		border: 1px solid #eaeaea;
		display: inline-block;
		padding: 4px 10px 4px 10px;
		border-radius: 6px;
		background: #fdfdfd;
		margin-left: 10px;
		cursor: pointer;
		line-height: 22px;
		height: 30px;
	}

	.chooseMark {
		color: white !important;
		background-color: #1890ff !important;
	}

	.fixed-card {
		position: fixed;
		right: 0;
		bottom: 0;
		left: 200px;
		z-index: 99;
		box-shadow: 0 -1px 2px rgb(240, 240, 240);
		margin-left: -33px;
		height: 76px;
	}

	.addper {
		margin-left: 5%;
	}

	.submission {
		margin-left: 70%;
	}

	.pictrue {
		width: 60px;
		height: 60px;
		border: 1px dotted rgba(0, 0, 0, 0.1);
		margin-right: 15px;
		margin-bottom: 10px;
		display: inline-block;
		position: relative;
		cursor: pointer;

		img {
			width: 100%;
			height: 100%;
		}

		.btndel {
			position: absolute;
			z-index: 1;
			width: 20px !important;
			height: 20px !important;
			left: 46px;
			top: -4px;
		}
	}

	.upLoad {
		width: 58px;
		height: 58px;
		line-height: 58px;
		border: 1px dotted rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		background: rgba(0, 0, 0, 0.02);
		cursor: pointer;
	}

	.input-display {
		display: none
	}
</style>