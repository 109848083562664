<template>
	<div>
		<header>
			<div class="ls-card">
				<el-page-header @back="$router.go(-1)" content="添加签证" />
			</div>
		</header>
		<!-- 添加签证 -->
		<div class="article-manager video-icon form-submit" id="shopp-manager">
			<el-card>
				<div style="margin-left: 80px; font-weight: bold;font-size: 20px;">
					入境日期：
					<el-date-picker value-format="yyyy-MM-dd" editable type="date" v-model="date" style="width: 200px"
						:picker-options="pickerOptions" />
				</div>
				<div style="margin-left: 80px; font-weight: bold;font-size: 20px;">
					行程名称：
					<el-input v-model="toruName" placeholder="请输入行程名稱" maxlength="50" style="width: 500px" />
				</div>
			</el-card>

			<el-card :bordered="false" dis-hover class="ivu-mt" v-for="(aitem,i) in allList" :key="i">

				<el-divider orientation="left">总共{{allList.length}}人 , 第{{i+1}}位
					<el-button v-if="i>0" style="margin-left: 30px;" @click="removeOne(i)" type="error" ghost>
						删除
					</el-button>
				</el-divider>
				<el-form class="formValidate mt20" ref="formValidate" :rules="ruleValidate" :model="aitem"
					@submit.native.prevent :loading="loading">
					<div style="margin-left: 5%;">
						<span style="color:#999999">请上传申请人资料：0人</span>
						<div style="display: flex;">
							<el-upload class="upload-demo" drag ref="upload" :show-file-list="false"
								:file-list="defaultList" :on-success="handleSuccess" :format="['jpg','jpeg','png']"
								:max-size="3072" :on-error="handleFormatError" :on-exceed="handleMaxSize"
								:before-upload="function (file) { return handleBeforeUpload(file, i)}" multiple
								:action="fileUrl" :headers="header">
								<i class="el-icon-upload"></i>
								<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
								<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过2mb</div>
							</el-upload>
						</div>
					</div>
					<table class="layui-table" width="90%" style="margin-left:5%" v-if="aitem.uploadList1.length>0">
						<thead>
							<td>预览</td>
							<td>资料类型</td>
							<td>新文件名</td>
							<td>原文件名</td>
							<td>大小</td>
							<td>操作</td>
						</thead>
						<tr v-for="(item,index) in aitem.uploadList1">
							<td class="last-td">
								<template v-if="item.status === 'success'">
									<img :src="item.response.data.url" fit="cover" style="width:100px;height:100px">
								</template>
								<el-progress :percentage="item.percentage"></el-progress>
							</td>
							<td style="width: 500px;">
								<div class="mark">
									<div v-for="(items, indexs) in makeList" :key="indexs"
										:class="item.type == indexs ? 'mark-item chooseMark' : 'mark-item'"
										@click="chooseMark(index, indexs,i,item.type)">
										{{ items }}
									</div>
								</div>
							</td>
							<td>
								<el-input v-model="item.newname" maxlength="30" style="width: 200px" />
							</td>
							<td>
								{{item.name}}
							</td>
							<td>
								{{sizeFilters(item.size)}}
							</td>
							<td>
								<el-button @click="handleRemove(index,i)" type="error">
									<i class="el-icon-delete-solid" size="20"></i>
								</el-button>
							</td>
						</tr>
					</table>
					<el-row style="margin-top:10px">
						<el-col :span="6">
							<el-form-item label="姓名：" prop="username" label-width="100px">
								<el-input v-model="aitem.username" placeholder="请输入姓名" maxlength="20"
									style="width: 80%;" />
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="身份证号码：" prop="idcard_no" label-width="100px">
								<el-input v-model="aitem.idcard_no" placeholder="请输入身份证号码" maxlength="18"
									style="width: 80%;" />
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="生日：" prop="birth" label-width="100px">
								<el-date-picker value-format="yyyy-MM-dd" editable type="date" v-model="aitem.birth"
									placeholder="请选择生日" style="width: 80%;" />
							</el-form-item>
						</el-col>

						<el-col :span="6">
							<el-form-item label="性别：" prop="sex" label-width="100px">
								<el-radio-group v-model="aitem.sex">
									<el-radio :label="1">
										<span>男</span>
									</el-radio>
									<el-radio :label="2">
										<span>女</span>
									</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="6">
							<el-form-item label="大通证号码：" prop="card_no" label-width="100px">
								<el-input v-model="aitem.card_no" placeholder="请输入大通证号码" maxlength="18"
									style="width: 80%;" />
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="大通证有效期：" prop="expiry" label-width="100px">
								<el-date-picker value-format="yyyy-MM-dd" editable type="date" v-model="aitem.expiry"
									placeholder="请选择有效期" style="width: 200px" />
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="6">
							<el-form-item label="教育程度：" prop="education" label-width="100px">
								<el-select v-model="aitem.education" style="width:200px">
									<el-option v-for="(item,index) in educationList" :value="item"
										:key="index">{{ item }}</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="职业：" prop="career" label-width="100px">
								<el-select v-model="aitem.career" style="width:200px">
									<el-option v-for="(item,index) in careerList" :value="item"
										:key="index">{{ item }}</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="公司名：" prop="company_name" label-width="100px">
								<el-input v-model="aitem.company_name" @blur="blur_company(i)"
									placeholder="请输入公司名+职位,如:XX公司总监" maxlength="50" style="width: 80%;" />
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="6">
							<el-form-item label="出生地：" prop="birth_province_zh" label-width="100px">
								<el-select v-model="aitem.birth_province_zh" style="width:200px;height:800px">
									<el-option v-for="(item,index) in cityList" :value="item"
										:key="index">{{ item }}</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="10">
							<el-form-item label="随行人(12岁以下需填写)：" label-width="170px">
								<el-input v-model="aitem.relative" placeholder="姓名" maxlength="10"
									style="width: 20%;" />
								<el-input v-model="aitem.relativeTitle" placeholder="称谓" maxlength="10"
									style="width: 20%;" />
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="联系电话：" prop="phone" label-width="100px">
								<el-input v-model="aitem.phone" placeholder="请输入联系电话" maxlength="11"
									style="width: 80%;" />
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</el-card>
		</div>
		<div style="height: 50px;">
		</div>
		<div class="fixed-card">
			<el-card :bordered="false" dis-hover>
				<el-form>
					<el-form-item>
						<el-button type="info" class="delcache" @click="delcache()">清空</el-button>
						<el-button type="success" class="addper" @click="addper">添加申请人</el-button>
						<el-button type="primary" class="submission" v-if="dis" disabled>创建中...</el-button>
						<el-button type="primary" class="submission" v-else @click="handleSubmit()">创建订单</el-button>
					</el-form-item>
				</el-form>
			</el-card>
		</div>
	</div>
</template>

<script>
	import {
		createOrder,
		getCateMark,
		analyzeImage
	} from '@/api/visa/order';
	import config from '@/config';

	export default {
		name: 'visa',
		components: {},
		props: {},
		data() {
			return {
				dis: false,
				loading: false,
				fileUrl: '',
				version: '',
				header: {},
				fileList: [],
				defaultList: [],
				date: '',
				toruName: '',
				phone: '',
				time: 0,
				allList: [{
					uploadList1: [],
					uploadList2: [],
					username: '',
					username_en: '',
					sex: 1,
					idcard_no: '',
					card_no: '',
					phone: '',
					birth: '',
					expiry: '',
					education: '',
					career: '',
					company_name: '',
					// birth_place: '',
					birth_province_zh: '福建',
					// address: '',
					idcard_img1: '',
					idcard_img2: '',
					idcard_src1: '',
					idcard_src2: '',
					photo: '',
					photo_src: '',
					card_img1: '',
					card_img2: '',
					card_src1: '',
					card_src2: '',
					// urgent_username: '',
					// urgent_phone: '',
					// urgent_sex: 1,
					// relationship: '',
					// urgent_address: '',
					// urgent_idcard_img1: '',
					// urgent_idcard_img2: '',
					// urgent_idcard_src1: '',
					// urgent_idcard_src2: '',
					// urgent_permit_img: '',
					// urgent_permit_src: '',
				}],
				width: 150,
				makeList: {},
				makeList1: {},
				ruleValidate: {
					phone: [{
							required: true,
							message: '联系电话不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: '手机号码格式不正确',
							trigger: 'blur',
						},
					],
					/* urgent_phone: [{
							required: true,
							message: '紧急联系人电话不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: '手机号码格式不正确',
							trigger: 'blur',
						},
					], */
					username: [{
						required: true,
						message: '姓名不能为空',
						trigger: 'blur'
					}, ],
					username_en: [{
						required: true,
						message: '英文名不能为空',
						trigger: 'blur'
					}, ],
					idcard_no: [{
						required: true,
						message: '身份证号码不能为空',
						trigger: 'blur'
					}, ],
					card_no: [{
						required: true,
						message: '大通证不能为空',
						trigger: 'blur'
					}, ],
					education: [{
						required: true,
						message: '请选择学历',
						trigger: 'blur'
					}, ],
					career: [{
						required: true,
						message: '请选择职业',
						trigger: 'blur'
					}, ],
					/* urgent_username: [{
						required: true,
						message: '紧急联系人姓名不能为空',
						trigger: 'blur'
					}, ],
					relationship: [{
						required: true,
						message: '请选择紧急联系人的关系',
						trigger: 'blur'
					}, ],
					relationship: [{
						required: true,
						message: '紧急联系人地址不能为空',
						trigger: 'blur'
					}, ], */
				},
				//教育程度
				educationList: [],
				//职业
				careerList: [],
				cityList: [],
				//关系
				relationshipList: [],
				model: '',
				chosI: 0,

				pickerOptions: {
					//选择第一次时间时触发
					disabledDate: time => { //通过disabledDate设置某个时间范围是否禁用可选
						let pickerType = time.getTime() < this.time
						//pickerType为布尔值，返回false或true；
						return pickerType
					}
				},
			}
		},
		computed: {

		},
		watch: {},

		mounted() {
			this.fileUrl = `${config.baseURL}/platformapi/upload/upload_image_visa`
			this.version = config.version
			this.header = {
				token: this.$store.getters.token,
				version: this.version
			}
			this.getCateMarks();
			
			const cachedData = localStorage.getItem('cacheData');
				console.log(cachedData,'cachedData')
			if (cachedData) {
			   let data = JSON.parse(cachedData);
			   this.allList = data.users;
			   this.date = data.date;
			   this.toruName = data.toruName;
			}
			
		},
		methods: {
			openMsg(msg) {
				this.$alert(msg, '错误提示', {
					confirmButtonText: '确定',
				});
			},
			blur_company(i) {
				if(this.allList[i].company_name.includes('有限公司')){
					this.allList[i].career = '商业'
				}else if(this.allList[i].company_name.includes('学校')){
					this.allList[i].career = '学生'
				}else if(this.allList[i].company_name.includes('學校')){
					this.allList[i].career = '学生'
				}else if(this.allList[i].company_name.includes('中学')){
					this.allList[i].career = '学生'
				}else if(this.allList[i].company_name.includes('中學')){
					this.allList[i].career = '学生'
				}else if(this.allList[i].company_name.includes('小学')){
					this.allList[i].career = '学生'
				}else if(this.allList[i].company_name.includes('小學')){
					this.allList[i].career = '学生'
				}else if(this.allList[i].company_name.includes('医院')){
					this.allList[i].career = '医疗人员'
				}else if(this.allList[i].company_name.includes('醫院')){
					this.allList[i].career = '医疗人员'
				}
			},
			getCateMarks() {
				getCateMark().then(async (res) => {
					this.educationList = res.education
					this.careerList = res.career
					this.relationshipList = res.relationship
					this.makeList = res.selfMarks
					this.makeList1 = res.urgentMarks
					// this.date = res.today
					// this.time = res.time
					this.cityList = res.citys
					this.phone = res.phone
					this.allList[0].phone = this.phone
				})
			},
			disabledDate(time) {
				// 示例：禁用今天之前的日期
				console.log(time.getTime(), 'time.getTime()')
				console.log(this.time, 'this.time')
				return time.getTime() < Date.now() - 8.64e7;
			},
			sizeFilters(num) {
				var size = num;
				if (!size) return '';
				var num = 1024.0;
				if (size < num) return size + 'B';
				if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + 'K'; //kb
				if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + 'M'; //M
				if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + 'G'; //G
				return (size / Math.pow(num, 4)).toFixed(2) + 'T'; //T
			},

			addper() {
				if (this.allList.length >= 20) {
					return this.openMsg('申请人数超限.');
				}
				let status = 1;
				this.allList.forEach(aitem => {
					if (aitem.uploadList1.length < 3) {
						status = 0;
					}
				})
				if (!status) {
					return this.openMsg('请等待上一位旅客图片上传完成.');
				}
				let formValidate = {
					uploadList1: [],
					uploadList2: [],
					username: '',
					sex: 1,
					idcard_no: '',
					card_no: '',
					phone: this.phone,
					expiry: '',
					education: '',
					career: '',
					company_name: '',
					// birth_place: '',
					birth_province_zh: '福建',
					birth: '',
					// address: '',
					idcard_img1: '',
					idcard_img2: '',
					idcard_src1: '',
					idcard_src2: '',
					photo: '',
					photo_src: '',
					card_img1: '',
					card_img2: '',
					card_src1: '',
					card_src2: '',
					/* urgent_username: '',
					urgent_phone: '',
					urgent_sex: 1,
					relationship: '',
					urgent_address: '',
					urgent_idcard_img1: '',
					urgent_idcard_img2: '',
					urgent_idcard_src1: '',
					urgent_idcard_src2: '',
					urgent_permit_img: '',
					urgent_permit_src: '', */
				}
				this.allList.push(formValidate);
			},

			removeOne(i) {
				this.allList.splice(i, 1);
			},

			handleRemove(index, i) {
				this.allList[i].uploadList1.splice(index, 1)
			},
			handleSuccess(res, file) {
				this.dis = false;
				if (res.code == 1) {
					file.percentage = 100;
				} else {
					return this.openMsg(res.msg);
				}
				this.loading = false;
				file.type = res.data.type || '';
				console.log(file.type, 'file.type')
				if (res.data.id_data) {
					let id_data = res.data.id_data;
					if (this.allList[this.chosI].idcard_no === '' && id_data.idcard_no && id_data.idcard_no !== '') {
						this.allList[this.chosI].idcard_no = id_data.idcard_no
						file.type = 1;
					}
					if (this.allList[this.chosI].username === '' && id_data.name && id_data.name !== '') {
						this.allList[this.chosI].username = id_data.name
					}
					if (id_data.sex === '男' && id_data.sex && id_data.sex !== '') {
						this.allList[this.chosI].sex = 1
					} else if (id_data.sex === '女' && id_data.sex && id_data.sex !== '') {
						this.allList[this.chosI].sex = 2
					}
					if (this.allList[this.chosI].birth === '' && id_data.birth && id_data.birth !== '') {
						this.allList[this.chosI].birth = id_data.birth
					}
					// if (this.allList[this.chosI].birth_place === '' && id_data.birth_place && id_data.birth_place !== '') {
					// 	this.allList[this.chosI].birth_place = id_data.birth_place
					// 	this.allList[this.chosI].address = id_data.birth_place
					// }
					if (this.allList[this.chosI].birth_province === '' && id_data.birth_province && id_data
						.birth_province !== '') {
						this.allList[this.chosI].birth_province = id_data.birth_province
					}
					if (this.allList[this.chosI].card_no === '' && id_data.card_no && id_data.card_no !== '') {
						this.allList[this.chosI].card_no = id_data.card_no
						file.type = 4;
					}
					if (this.allList[this.chosI].expiry === '' && id_data.expiry && id_data.expiry !== '') {
						this.allList[this.chosI].expiry = id_data.expiry
						file.type = 4;
					}
					if (this.allList[this.chosI].username_en === '' && id_data.username_en && id_data.username_en !== '') {
						this.allList[this.chosI].username_en = id_data.username_en
					}
				}
				this.allList[this.chosI].uploadList1.push(file);
				this.$forceUpdate();
			},
			handleFormatError(file) {
				this.loading = false;
				this.$Notice.warning({
					title: 'The file format is incorrect',
					desc: 'File format of ' + file.name +
						' is incorrect, please select jpg、png、pdf.'
				});
			},
			handleMaxSize(file) {
				this.loading = false;
				this.$Notice.warning({
					title: 'Exceeding file size limit',
					desc: 'File  ' + file.name + ' is too large, no more than 3M.'
				});
			},
			handleBeforeUpload(file, i) {
				this.dis = true;
				this.loading = true;
				this.chosI = i;
				console.log(this.allList[i].uploadList1.length, '111111111111111')
				if (this.allList[i].uploadList1.length > 5) {
					return this.$message({
						type: 'error',
						message: '申请人资料最多5张!'
					});
				}
				return true;
			},

			chooseMark(imgIndex, index, i, type) {
				console.log(type, 'type')
				console.log(index, 'index')
				this.$set(this.allList[i].uploadList1[imgIndex], 'type', index);
				if (!type || (!this.allList[i].idcard_no && index === '1') || (!this.allList[i].card_no && index ===
					'4') || (!this.allList[i].card_no && index === '5')) {
					let param = {
						image_src: this.allList[i].uploadList1[imgIndex].response.data.src,
						type: index
					}
					analyzeImage(param).then(async (res) => {
						if (res.id_data) {
							let id_data = res.id_data;
							if (this.allList[i].idcard_no === '' && id_data.idcard_no && id_data.idcard_no !==
								'') {
								this.allList[i].idcard_no = id_data.idcard_no
							}
							if (this.allList[i].username === '' && id_data.name && id_data.name !== '') {
								this.allList[i].username = id_data.name
							}
							if (id_data.sex === '男' && id_data.sex && id_data.sex !== '') {
								this.allList[i].sex = 1
							} else if (id_data.sex === '女' && id_data.sex && id_data.sex !== '') {}
							if (this.allList[i].birth === '' && id_data.birth && id_data.birth !== '') {
								this.allList[i].birth = id_data.birth
							}
							// if (this.allList[i].birth_place === '' && id_data.birth_place && id_data.birth_place !== '') {
							// 	this.allList[i].birth_place = id_data.birth_place
							// }
							if (this.allList[i].birth_province === '' && id_data.birth_province && id_data
								.birth_province !== '') {
								this.allList[i].birth_province = id_data.birth_province
							}
							if (this.allList[i].card_no === '' && id_data.card_no && id_data.card_no !== '') {
								this.allList[i].card_no = id_data.card_no
							}
							if (this.allList[i].expiry === '' && id_data.expiry && id_data.expiry !== '') {
								this.allList[i].expiry = id_data.expiry
							}
							if (this.allList[i].username_en === '' && id_data.username_en && id_data
								.username_en !== '') {
								this.allList[i].username_en = id_data.username_en
							}
							if (id_data.photo && id_data.photo !== '' && id_data.photo_src !== '' && id_data.photo_src !== '') {
								this.allList[i].photo = id_data.photo
								this.allList[i].photo_src = id_data.photo_src
								this.allList[i].uploadList1[imgIndex].response.data.url = id_data.photo
								this.$set(this.allList[i].uploadList1[imgIndex].response.data, 'url', id_data.photo);
							}
						}
					})
				}

				this.$forceUpdate();
			},



			chooseMark1(imgIndex, index, i) {
				this.$set(this.allList[i].uploadList2[imgIndex], 'type', index);
			},

			handleRemove1(index, i) {
				this.allList[i].uploadList2.splice(index, 1)
			},

			handleSuccess1(res, file) {
				this.dis = false;
				if (res.code == 1) {
					file.percentage = 100;
				} else {
					return this.$message({
						type: 'error',
						message: '上传失败!'
					});
				}
				this.loading = false;
				if (res.data.id_data && (res.data.id_data.idcard_no || res.data.id_data.name)) {
					let id_data = res.data.id_data;
					if (this.allList[this.chosI].urgent_username === '' && id_data.name && id_data.name !== '') {
						this.allList[this.chosI].urgent_username = id_data.name
					}
					if (id_data.sex === '男' && id_data.sex && id_data.sex !== '') {
						this.allList[this.chosI].urgent_sex = 1
					} else if (id_data.sex === '女' && id_data.sex && id_data.sex !== '') {
						this.allList[this.chosI].urgent_sex = 2
					}
					// if (this.allList[this.chosI].urgent_address === '' && id_data.birth_place && id_data.birth_place !==
					// 	'') {
					// 	this.allList[this.chosI].urgent_address = id_data.birth_place
					// }
					file.type = 11;
				}
				this.allList[this.chosI].uploadList2.push(file)
			},
			handleBeforeUpload1(file, i) {
				this.dis = true;
				this.loading = true;
				this.chosI = i;
				if (this.allList[i].uploadList2.length > 5) {
					this.$Notice.warning({
						title: '紧急联系人资料最多5张.'
					});
				}
				return true;
			},

			getdate(dates) {
				const date = new Date(dates);
				const year = date.getFullYear();
				const month = (date.getMonth() + 1).toString().padStart(2, '0');
				const day = date.getDate().toString().padStart(2, '0');
				return year + '-' + month + '-' + day
			},

			//创建订单
			handleSubmit() {
				console.log(this.allList,'this.allList')
				if (this.date == '') {
					return this.$message({
						type: 'error',
						message: '请选择入境日期!'
					});
				}
				let date = this.getdate(this.date)
				let cache = {
						users: this.allList,
						'type': 0,
						date: date,
						toruName: this.toruName
					};
				localStorage.setItem('cacheData', JSON.stringify(cache));
				let status = 0;
				let num = 0;
				let file_name = '';
				this.allList.forEach(aitem => {
					aitem.uploadList1.forEach(item => {
						if (!item.type) {
							status = 1;
							file_name = item.name
						}
						if (item.type === 1 || item.type === '1') {
							aitem.idcard_img1 = item.response.data.url;
							aitem.idcard_src1 = item.response.data.src;
						} else if (item.type === 2 || item.type === '2') {
							aitem.idcard_img2 = item.response.data.url;
							aitem.idcard_src2 = item.response.data.src;
						} else if (item.type === 3 || item.type === '3') {
							aitem.photo = item.response.data.url;
							aitem.photo_src = item.response.data.src;
						} else if (item.type === 4 || item.type === '4') {
							aitem.card_img1 = item.response.data.url;
							aitem.card_src1 = item.response.data.src;
						} else if (item.type === 5 || item.type === '5') {
							aitem.card_img2 = item.response.data.url;
							aitem.card_src2 = item.response.data.src;
						}
					});
					/* aitem.uploadList2.forEach(item => {
						if (!item.type) {
							status = 1;
							file_name = item.name
						}
						if (item.type === 11 || item.type === '11') {
							aitem.urgent_idcard_img1 = item.response.data.url;
							aitem.urgent_idcard_src1 = item.response.data.src;
						} else if (item.type === 12 || item.type === '12') {
							aitem.urgent_idcard_img2 = item.response.data.url;
							aitem.urgent_idcard_src2 = item.response.data.src;
						} else if (item.type === 13 || item.type === '13') {
							aitem.urgent_permit_img = item.response.data.url;
							aitem.urgent_permit_src = item.response.data.src;
						}
					}); */
					if (aitem.uploadList1.length < 3) {
						num++;
					}
					/* if (aitem.uploadList2.length < 3) {
						num++;
					} */
					if (aitem.expiry !== '') {
						aitem.expiry = this.getdate(aitem.expiry)
					}
				});
				console.log(this.allList, 'this.allList')
				if (status === 1) {
					return this.$message({
						type: 'error',
						message: "请选择资料类型(" + file_name + ")"
					});
				}
				if (num > 0) {
					return this.$message({
						type: 'error',
						message: "请上传必要文件"
					});
				}
				this.dis = true;
				this.loading = true;
				cache = {
						users: this.allList,
						'type': 0,
						date: date,
						toruName: this.toruName
					};
				localStorage.setItem('cacheData', JSON.stringify(cache));
				createOrder({
						users: this.allList,
						'type': 0,
						date: date,
						toruName: this.toruName
					})
					.then(async (res) => {
						localStorage.setItem("cacheData", null);
						this.loading = false;
						this.$message({
							type: 'success',
							message: '创建成功'
						});
						// setTimeout(() => {
						return this.$router.push({
							path: '/visa/edit?id=' + res.order_id
						});
						// }, 500);
						return;
					})
					.catch((res) => {
						this.loading = false;
						this.dis = false;
						return this.$message({
							type: 'error',
							message: "创建失败！"
						});
					})
			},
			delcache(){
				localStorage.setItem("cacheData", null);
				this.toruName = '';
				this.date = '';
				this.allList = [{
					uploadList1: [],
					uploadList2: [],
					username: '',
					username_en: '',
					sex: 1,
					idcard_no: '',
					card_no: '',
					phone: '',
					birth: '',
					expiry: '',
					education: '',
					career: '',
					company_name: '',
					birth_province_zh: '福建',
					idcard_img1: '',
					idcard_src1: '',
					photo: '',
					photo_src: '',
					card_img1: '',
					card_src1: '',
				}];
			}
		},
	}
</script>

<style lang="scss" scoped>
	.el-card {
		max-height: 1200px !important;
	}
	.mark-item {
		border: 1px solid #eaeaea;
		display: inline-block;
		padding: 4px 10px 4px 10px;
		border-radius: 6px;
		background: #fdfdfd;
		margin-left: 10px;
		cursor: pointer;
		line-height: 22px;
		height: 30px;
	}

	.chooseMark {
		color: white !important;
		background-color: #1890ff !important;
	}

	.fixed-card {
		position: fixed;
		right: 0;
		bottom: 0;
		left: 200px;
		z-index: 99;
		box-shadow: 0 -1px 2px rgb(240, 240, 240);
		margin-left: -33px;
		height: 76px;
	}

	.addper {
		margin-left: 5%;
	}
	.delcache{
		margin-left: 1%;
	}
	.submission {
		margin-left: 70%;
	}

	.pictrue {
		width: 60px;
		height: 60px;
		border: 1px dotted rgba(0, 0, 0, 0.1);
		margin-right: 15px;
		margin-bottom: 10px;
		display: inline-block;
		position: relative;
		cursor: pointer;

		img {
			width: 100%;
			height: 100%;
		}

		.btndel {
			position: absolute;
			z-index: 1;
			width: 20px !important;
			height: 20px !important;
			left: 46px;
			top: -4px;
		}
	}

	.upLoad {
		width: 58px;
		height: 58px;
		line-height: 58px;
		border: 1px dotted rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		background: rgba(0, 0, 0, 0.02);
		cursor: pointer;
	}

	.input-display {
		display: none
	}
</style>